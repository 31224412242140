<template>
    <b-modal title="Add new" v-model="addModalActive" no-close-on-backdrop>
        <template #default>
            <b-form-group>
                <label for="name">Name:</label>
                <b-form-input
                    id="name"
                    type="text"
                    placeholder="Name"
                    v-model="addObject.name"
                />
            </b-form-group>
            <b-form-group>
                <label>URL:</label>
                <b-form-input
                    type="text"
                    placeholder="URL"
                    v-model="addObject.url"
                />
            </b-form-group>
            <b-form-group>
                <label>Update interval:</label>
                <b-form-input
                    type="text"
                    placeholder="Update interval"
                    v-model="addObject.update_interval"
                />
            </b-form-group>

            <b-form-group>
                <label for="types">Type:</label>
                <v-select
                    id="types"
                    v-model="addObject.epg_type"
                    :reduce="type => type.id"
                    label="name"
                    :options="[{id: 0, name: 'XML File'}, {id: 1, name: 'GZipped XML File'}, {id: 2, name: 'XML in tar.gz'}, {id: 3, name: 'XML in zip'}]"
                />

            </b-form-group>

        </template>

        <template #modal-footer>

            <b-button variant="danger" @click="addModalActive = false">
                <feather-icon
                    icon="XIcon"
                    class="mr-50"
                />
                <span class="align-middle">Close</span>
            </b-button>

            <b-button variant="primary" @click="addItem">
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">Add</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>

    import { BButton, BFormGroup, BFormInput, BModal } from 'bootstrap-vue'
    import vSelect from 'vue-select'

    export default {
        components: {
            BButton,
            BModal,
            BFormInput,
            BFormGroup,
            vSelect
        },
        data() {
            return {

                addObject: {},
                addModalActive: false

            }
        },
        methods: {
            open() {
                this.addModalActive = true
            },
            addItem() {
                const thisIns = this
                const addPromise = this.$http.post('/api/management/v1/epg_provider/', {
                    name: this.addObject.name,
                    url: this.addObject.url,
                    epg_type: Number(this.addObject.epg_type),
                    update_interval: Number(this.addObject.update_interval)
                })

                addPromise.then(function(response) {
                    thisIns.addEPGProviderPrompt = false
                    thisIns.$printSuccess('EPG Provider added')
                    thisIns.$nextTick(() => {
                        thisIns.$router.push(`/epg_provider/${  response.data.id}`)
                    })
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.$emit('itemAdded')
                })
            }
        }

    }

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
