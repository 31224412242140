<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card>
                <b-button variant="primary" @click="$refs.addItemModal.open()" class="mt-1">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>

                <basic-table :columns="columns" :data="items" v-slot="props">
                    <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-button variant="success" @click="$router.push(`/epg_provider/${  props.row.id}`)" class="mr-1">
                            <feather-icon
                                icon="EyeIcon"
                            />
                        </b-button>
                    </div>
                    <span v-else-if="props.column.displayType === 2">
                        {{moment(props.formattedRow[props.column.field]).format('YYYY/MM/DD HH:mm:ss')}}
                    </span>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </basic-table>
            </b-card>
        </b-overlay>

        <EPGProviderChannels :provider-name-column="true" :url="'/api/management/v1/epg_provider_channels'"/>

        <AddProviderModal ref="addItemModal" v-on:itemAdded="loadData"/>

    </div>
</template>
<script>

    import { BButton, BCard, BOverlay } from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'
    import AddProviderModal from '@/views/TV/EPG/AddProviderModal'
    import EPGProviderChannels from '@/views/TV/EPG/EPGProviderChannels'

    export default {
        components: {
            EPGProviderChannels,
            AddProviderModal,
            BOverlay,
            BCard,
            BButton,
            BasicTable
        },
        data() {
            return {

                items: [],

                dataLoaded: false,

                columns: [
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'EPG Type',
                        displayType: 0,
                        field(rowObj) {
                            return [{id: 0, name: 'XML File'}, {id: 1, name: 'GZipped XML File'}, {id: 2, name: 'XML in tar.gz'}, {id: 3, name: 'XML in zip'}][rowObj.epg_type].name
                        },
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Update interval',
                        displayType: 0,
                        field: 'update_interval',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Last update',
                        displayType: 2,
                        field: 'last_update',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Last entry',
                        displayType: 2,
                        field: 'last_entry',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Channels used',
                        displayType: 0,
                        field: 'channels_used',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: '',
                        displayType: 1,
                        field: 'actions',
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],

                addObject: {},
                addModalActive: false
            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false

                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/epg_provider/')
                loadPromise.then(function(response) {
                    thisIns.items = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            lastUpdateRenderer(rowObj) {
                return this.moment(rowObj.last_update).format('YYYY/MM/DD HH:mm:ss')
            },
            lastEntryRenderer(rowObj) {
                return this.moment(rowObj.last_entry).format('YYYY/MM/DD HH:mm:ss')
            }
        },
        mounted() {
            this.loadData()
        }
    }

</script>
