<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card>
                <basic-table :columns="columns" :data="channels" v-slot="props">
                    <div v-if="props.column.displayType === 1" class="d-flex justify-content-center" style="min-width: 150px">
                        <img v-if="props.row.icon && props.row.icon.length > 0" :src="props.row.icon" style="max-width: 100%; max-height: 100px;" alt="Image"/>
                    </div>
                    <span v-else-if="props.column.displayType === 2">
                        {{ moment(props.formattedRow[props.column.field]).format('YYYY/MM/DD HH:mm:ss') }}
                    </span>
                    <span v-else-if="props.column.displayType === 3">
                        {{ props.formattedRow[props.column.field] }}%
                    </span>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </basic-table>
            </b-card>
        </b-overlay>
    </div>
</template>
<script>

    import BasicTable from '@/views/components/BasicTable'
    import { BCard, BOverlay } from 'bootstrap-vue'

    export default {
        components: {
            BOverlay,
            BCard,
            BasicTable
        },
        props: {
            url: {
                type: String,
                required: true
            },
            providerNameColumn: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {

                channels: [],
                dataLoaded: false,

                columns: [
                    {
                        label: '',
                        displayType: 1,
                        field: 'icon',
                        filterOptions: {
                            enabled: false
                        }
                    },
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'ID',
                        displayType: 0,
                        field: 'channel_id',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Last entry',
                        displayType: 2,
                        field: 'last_entry',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Thumbnail percentage',
                        displayType: 3,
                        field: 'thumbnail_percentage',
                        filterOptions: {
                            enabled: true
                        }
                    }
                ]

            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false

                const thisIns = this
                const loadPromise = this.$http.get(this.url)
                loadPromise.then(function(response) {
                    thisIns.channels = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.dataLoaded = true
                })
            }
        },
        mounted() {
            if (this.providerNameColumn) {
                this.columns.push(
                    {
                        label: 'Provider',
                        displayType: 0,
                        field: 'epg_provider_name',
                        filterOptions: {
                            enabled: true
                        }
                    }
                )
            }
            this.loadData()
        }
    }

</script>
